export function showLoadingTextLandscape() {
  const loadingOverlay = document.createElement("div");
  loadingOverlay.id = "loadingOverlay";
  loadingOverlay.style.position = "absolute";
  loadingOverlay.style.top = "0";
  loadingOverlay.style.left = "0";
  loadingOverlay.style.width = "100%";
  loadingOverlay.style.height = "100%";
  loadingOverlay.style.backgroundColor = "rgba(255, 255, 255, 1)";
  loadingOverlay.style.zIndex = "1000";
  loadingOverlay.style.display = "flex";
  loadingOverlay.style.flexDirection = "column";
  loadingOverlay.style.justifyContent = "space-between";
  loadingOverlay.style.alignItems = "center";
  document.body.appendChild(loadingOverlay);

  const logoImage = document.createElement("img");
  logoImage.src = "logo.jpg";
  logoImage.alt = "Loading Logo";
  logoImage.style.width = "300px";
  logoImage.style.height = "100px";
  logoImage.style.marginTop = "auto";
  logoImage.style.marginBottom = "auto";
  loadingOverlay.appendChild(logoImage);

  const loadingBottomContainer = document.createElement("div");
  loadingBottomContainer.style.display = "flex";
  loadingBottomContainer.style.flexDirection = "column";
  loadingBottomContainer.style.alignItems = "center";
  loadingBottomContainer.style.width = "100%";
  loadingBottomContainer.style.marginBottom = "60px";
  loadingOverlay.appendChild(loadingBottomContainer);

  const loadingBarContainer = document.createElement("div");
  loadingBarContainer.style.width = "60%";
  loadingBarContainer.style.maxWidth = "600px";
  loadingBarContainer.style.height = "15px";
  loadingBarContainer.style.backgroundColor = "#aeaeae";
  loadingBarContainer.style.borderRadius = "15px";
  loadingBarContainer.style.overflow = "hidden";
  loadingBottomContainer.appendChild(loadingBarContainer);

  const loadingBar = document.createElement("div");
  loadingBar.id = "loadingBar";
  loadingBar.style.width = "0%";
  loadingBar.style.height = "100%";
  loadingBar.style.backgroundColor = "#2e78cc";
  loadingBar.style.borderRadius = "15px 0 0 15px";
  loadingBarContainer.appendChild(loadingBar);

  const loadingText = document.createElement("div");
  loadingText.style.marginTop = "10px";
  loadingText.style.fontFamily = "Monsterat, sans-serif";
  loadingText.style.fontSize = "16px";
  loadingText.style.color = "#363636";
  loadingText.textContent =
    "Ładowanie konfiguratora, prosimy o chwilę cierpliwości...";
  loadingBottomContainer.appendChild(loadingText);

  const bottomFlexRow = document.createElement("div");
  bottomFlexRow.style.display = "flex";
  bottomFlexRow.style.justifyContent = "space-between";
  bottomFlexRow.style.width = "100%";
  bottomFlexRow.style.padding = "0 20px";
  bottomFlexRow.style.position = "absolute";
  bottomFlexRow.style.bottom = "20px";

  const text1 = document.createElement("div");
  text1.style.fontFamily = "Monsterat, sans-serif";
  text1.style.fontSize = "16px";
  text1.style.color = "#363636";
  text1.textContent = "© 2025 Marciniak Ogrodzenia";
  bottomFlexRow.appendChild(text1);

  const text2Container = document.createElement("div");
  text2Container.style.display = "flex";
  text2Container.style.alignItems = "center";

  const text2 = document.createElement("div");
  text2.style.fontFamily = "Monsterat, sans-serif";
  text2.style.fontSize = "16px";
  text2.style.color = "#363636";
  text2.textContent = "Created by";
  text2Container.appendChild(text2);

  const logoLink = document.createElement("a");
  logoLink.href = "https://creative.stellarcompany.eu/";
  logoLink.target = "_blank";

  const logoSvg = document.createElement("img");
  logoSvg.src = "stlr-logo2.png";
  logoSvg.alt = "STLR Logo";
  logoSvg.style.width = "150px";
  logoSvg.style.height = "25px";
  logoSvg.style.marginLeft = "5px";

  logoLink.appendChild(logoSvg);
  text2Container.appendChild(logoLink);

  bottomFlexRow.appendChild(text2Container);

  loadingOverlay.appendChild(bottomFlexRow);
}
