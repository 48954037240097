const calculateRoomPrice = (dimensionsData, dimensions, selectedRoomOption, isWallTypeChecked) => {
  const kosztBlachyPir = dimensionsData.find(
    (d) => d["Rozmiar (szer. x dł.)"] === "Koszt blachy - pir"
  )["Cena Netto (zł) zaciagana do konfiguratora"];
  
  const kosztDrzwiPir = dimensionsData.find(
    (d) => d["Rozmiar (szer. x dł.)"] === "Koszt drzwi - pir"
  )["Cena Netto (zł) zaciagana do konfiguratora"];
  
  const kosztBlachyFala = dimensionsData.find(
    (d) => d["Rozmiar (szer. x dł.)"] === "Koszt blachy - fala"
  )["Cena Netto (zł) zaciagana do konfiguratora"];
  
  const kosztDrzwiFala = dimensionsData.find(
    (d) => d["Rozmiar (szer. x dł.)"] === "Koszt drzwi - fala"
  )["Cena Netto (zł) zaciagana do konfiguratora"];
  
  const kosztBlachy = isWallTypeChecked ? kosztBlachyFala : kosztBlachyPir;
  const kosztDrzwi = isWallTypeChecked ? kosztDrzwiFala : kosztDrzwiPir;
  
  //console.log(`dimesions - length ${dimensions.length} `);
  
  let roomPrice = 0;
  const roomLength = dimensions.length / 100;
  const roomWidth = dimensions.width / 100;
  let roomHeight = dimensions.height / 100;
  
  // Adjust roomHeight based on isWallTypeChecked
  if (isWallTypeChecked) {
    roomHeight += 0.8;
  } else {
    roomHeight -= 0.1;
  }

  //console.log(`${kosztDrzwi} koszt drzwi`);
  //console.log(`${kosztBlachy} koszt blachy`);

  if (selectedRoomOption === "3.2.a" || selectedRoomOption === "3.2.c") {
    roomPrice = (roomLength * 2 + 6) * roomHeight * kosztBlachy + kosztDrzwi;
  } else if (selectedRoomOption === "3.2.b") {
    roomPrice = (roomWidth * 2 + 6) * roomHeight * kosztBlachy + kosztDrzwi;
  }

  //console.log(`cena pomieszczenia koszt drzwi ${roomPrice}`);
  return roomPrice;
};

export { calculateRoomPrice };
