import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleSquare,
  selectSelectedSquares,
  clearSelection,
  updateToInitialSelection,
  updateToExtendedInitialSelection
} from "../../store/reducers/panelPlacementSlice";
import { selectSelectedId } from "../../store/reducers/modelChoiceSlice";
import { selectDimensionsBySelectedId } from "../../store/reducers/dimensionsSlice";
import "./PanelPlacementChoiceComponent.css";
import {
  squaresData1,
  squaresData1Extended,
  squaresData2,
  squaresData2Extended,
  squaresData3,
  squaresData3Extended,
  squaresData4,
  squaresData4Extended,
  squaresData5,
  squaresData5Extended,
  squaresData6,
  squaresData6Extended,
} from "./squareDataConfig";
import BackgroundPlaceholderComponent from './BackgroundPlaceholderComponent';
import { selectSelectedId as selectPanelChoiceId } from "../../store/reducers/panelChoiceSlice";

const ClickableSquare = ({ id, onClick, isSelected, isEnabled = true, isVisible = true, style }) => {
  const darkColor = "rgba(13,13,13,0.8)";
  const selectedColor = "rgba(130, 194, 37, 0.8)";
  const unselectedColor = "rgba(153,153,153,1)";

  const squareStyle = {
    ...style,
    backgroundColor: isEnabled ? (isSelected ? selectedColor : unselectedColor) : darkColor,
    cursor: isEnabled ? 'pointer' : 'default',
    display: isVisible ? 'block' : 'none',
    pointerEvents: isEnabled ? 'auto' : 'none'  // Disable pointer events if not enabled
  };

  return (
    <div
      className="clickable-square"
      id={id}
      onClick={isEnabled ? () => onClick(id) : undefined}
      style={squareStyle}
    >
      {/*    */}
      {id}
    </div>
  );
};

const PanelPlacementChoiceComponent = () => {
  const dispatch = useDispatch();
  const selectedId = useSelector(selectSelectedId);
  const selectedSquares = useSelector(selectSelectedSquares);
  const panelChoiceId = useSelector(selectPanelChoiceId);
  const dimensions = useSelector((state) =>
    selectDimensionsBySelectedId(state, selectedId)
  );
  const currentLength = dimensions.length;
  const { selectedGarageOption, selectedRoomOption } = useSelector((state) => state.shedOptions);

  function calculateDynamicSquareStyle(selectedId, currentLength) {
    if (selectedId === "1.b" || selectedId === '1.d' || selectedId === '1.e' || selectedId === '1.f') {
      return currentLength > 699
        ? { width: "6.25vw", height: "4.3vw" }
        : { width: "6.25vw", height: "4.6vw" };
    } else if (selectedId === "1.a") {
      return currentLength > 699
        ? { width: "6.25vw", height: "4.3vw" }
        : { width: "6.25vw", height: "4.6vw" };
    } else if (selectedId === "1.c") {
      return currentLength > 699
        ? { width: "6.25vw", height: "4.3vw" }
        : { width: "6.25vw", height: "4.6vw" };
    } else {
      return { width: "7.37vw", height: "5.05vw" };
    }
  }

  const dynamicSquareStyle = calculateDynamicSquareStyle(selectedId, currentLength);

  useEffect(() => {
    if (currentLength > 699) {
      dispatch(updateToExtendedInitialSelection());
    } else {
      dispatch(updateToInitialSelection());
    }
  }, [selectedId, panelChoiceId, currentLength, dispatch]);

  let squaresData;
  if (selectedId === "1.a") {
    squaresData = currentLength > 699 ? squaresData1Extended : squaresData1;
  } else if (selectedId === "1.b") {
    squaresData = currentLength > 699 ? squaresData2Extended : squaresData2;
  } else if (selectedId === "1.c") {
    squaresData = currentLength > 699 ? squaresData3Extended : squaresData3;
  } else if (selectedId === "1.d") {
    squaresData = currentLength > 699 ? squaresData4Extended : squaresData4;
  } else if (selectedId === "1.e") {
    squaresData = currentLength > 699 ? squaresData5Extended : squaresData5;
  } else if (selectedId === "1.f") {
    squaresData = currentLength > 699 ? squaresData6Extended : squaresData6;
  }

  const containerStyle =
    (selectedId === "1.b" || selectedId === "1.d" || selectedId === "1.e" || selectedId === "1.f") || selectedId === "1.c"
      ? { width: "100%" }
      : { width: "100%" };

  const handleClick = (id) => {
    dispatch(toggleSquare(id));
  };

  const isSquareEnabled = (id) => {
    if (selectedId === "1.b" || selectedId === '1.d' || selectedId === '1.e' || selectedId === '1.f') {
      if (currentLength > 699) {
        if (['6.1.y'].includes(id) && (selectedGarageOption.includes('3.1.a') || selectedRoomOption === '3.2.a')) {
          return false;
        }
        if (['6.1.z'].includes(id) && (selectedGarageOption.includes('3.1.b') || selectedRoomOption === '3.2.c')) {
          return false;
        }
      }
      if (['6.1.a', '6.1.b', '6.1.c'].includes(id) && selectedRoomOption === '3.2.a') {
        return false;
      }
      if (['6.1.f', '6.1.g', '6.1.h'].includes(id) && selectedRoomOption === '3.2.c') {
        return false;
      }
      if (['6.1.d', '6.1.e'].includes(id) && selectedRoomOption === '3.2.b') {
        return false;
      }

      // Additional conditions for garage options
      if (['6.1.a', '6.1.b', '6.1.c', '6.1.d'].includes(id) && selectedGarageOption.includes('3.1.a')) {
        return false;
      }
      if (['6.1.f', '6.1.g', '6.1.h', '6.1.e'].includes(id) && selectedGarageOption.includes('3.1.b')) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className="clickable-squares-container" style={containerStyle}>
      {(selectedId === '1.a' || selectedId === '1.b' || selectedId === '1.c' || selectedId === '1.d' || selectedId === '1.e' || selectedId === '1.f') && (
        <BackgroundPlaceholderComponent selectedId={selectedId} currentLength={currentLength} />
      )}
      {squaresData.map(({ id, top, left }) => {
        const isVisible = !(id === '6.1.y' || id === '6.1.z') || currentLength > 699;

        return (
          <ClickableSquare
            key={id}
            id={id}
            onClick={handleClick}
            isSelected={selectedSquares[id]}
            isEnabled={isSquareEnabled(id)}
            isVisible={isVisible}
            style={{
              ...dynamicSquareStyle,
              top,
              left,
              position: "absolute",
              zIndex: 1,
            }}
          />)
      })}

      <button className="resetbutton" onClick={() => dispatch(clearSelection())}>Clear Selection</button>
    </div>
  );
};

export default PanelPlacementChoiceComponent;
